export default {
    header: {
        search_placeholder: 'Поиск по адресу или домену',
        search_domain_error: 'Домен не существует либо к нему не привязан адрес кошелька',
        search_address_error: 'Введите адрес TON или домен в зоне .ton',
    },

    indexpage: {
        search_placeholder: 'Поиск адресов TON, доменов и транзакций...',
        search_domain_error: 'Домен не существует либо к нему не привязан адрес кошелька',
        search_address_error: 'Введите адрес TON или домен в зоне .ton',
    },

    search: {
        category: {
            wallet: 'Кошелёк',
            nft: 'Коллекция NFT',
            pool: 'Номинатор-пул',
        },
    },

    address: {
        info: {
            address: 'Адрес',
            copy_success: 'Адрес скопирован',
            balance: 'Баланс',
            last_activity: 'Активность',
            state: 'Состояние',
            type_frozen: 'Замороженный',
            type_active: 'Активный',
            type_inactive: 'Неактивный',
            contract_type: 'Контракт',
            no_activity: 'Не было транзакций',
        },

        contract_type: {
            wallet: 'Кошелёк',
            nft_collection: 'NFT-коллекция',
            nft_item: 'NFT-итем',
            jetton: 'Жетон',
            jetton_wallet: 'Баланс жетонов',
            nominator_pool: 'Номинатор-пул',
            unknown: 'Неизвестен',
            domain_name: 'Домен',
            dns_domain: 'Домен .ton',
            nft_sale: 'Продажа NFT',
            nft_offer: 'NFT-оффер',
            subscription: 'Подписка',
            telegram_username: 'Юзернейм в Telegram',
            telegram_number: 'Анонимный номер',
            other: 'Прочее',
            sale: {
                sale_info: '{0} за {1}',
                on_auction: 'на аукционе',
                on_sale: 'продаётся',
            },
            storage_provider: {
                name: 'Хранилище',
                rate_template: 'Цена в {period}:',
                rate_value_hour: 'час',
                rate_value_day: 'день',
                rate_value_month: 'месяц',
                rate_value_year: 'год',
                bytes_template: 'за {value}',
                bytes_value_mb: 'МиБ',
                bytes_value_gb: 'ГиБ',
                bytes_value_tb: 'ТиБ',
            },
            subscription_modal_header: 'Контракт подписки',
            nft_sale_modal_header: 'Контракт продажи NFT',
        },

        tab_nfts: 'NFT',
        tab_transactions: 'Транзакции',
        tab_contract: 'Контракт',
        tab_tokens: 'Жетоны', 

        tx_table: {
            empty: 'История транзакций пуста',
            age: 'Дата',
            from: 'Отправитель',
            to: 'Получатель',
            value: 'Сумма',
            input: 'ВХД',
            output: 'ИСХ',
            log: 'ЛОГ',
            nowhere: 'Вникуда',
            external: 'извне',
            address_count: 'пусто | {n} адрес | {n} адреса | {n} адресов',
        },

        nfts: {
            no_nfts: 'Кажется, на этом адресе нет NFT',
            no_collection: 'Не в коллекции',
        },

        tokens: {
            no_tokens: 'На этом адресе нет жетонов',
            table_token_name: 'Жетон',
            table_token_balance: 'Баланс',
            table_token_jetton_wallet: 'Жетон-кошелёк',
        },

        contract: {
            admonition: {
                verified_title: 'Верифицированные исходники',
                verified_text: 'Результат компиляции данных исходных файлов совпадает с байт-кодом контракта в блокчейне. Проверка была выполнена с помощью специального {verifier}.',
                unverified_title: 'Неизвестный контракт',
                unverified_text: 'У нас нет исходных кодов этого контракта. Загрузить их можно с помощью специального {verifier}.',
                verifier_link_text: 'верификатора',
                inactive_title: 'Неактивный адрес',
                inactive_text: 'В блокчейне нет никаких данных, связанных с этим адресом — либо им никогда не пользовались, либо контракт был по каким-то причинам удалён. В данный момент он просто бесцельно существует в цифровом небытии.',
            },

            tab_raw_source: 'Байт-код',
            tab_raw_data: 'Исходные данные',

            info: 'Информация',
            code: 'Исходный код',
            data: 'Данные',
            source_hex: 'hex',
            source_tvm: 'disassembled',
            source_base64: 'base64',
            source_cells: 'cells',
            info_description: 'На этом адресе лежит смарт-контракт {type}.',
            info_source: 'Его исходный код {link}.',
            source_code: 'доступен на GitHub',
            types: {
                wallet: 'кошелька',
                lockup_wallet: 'защищённого кошелька',
                nft_collection: 'коллекции NFT',
                nft_item: 'NFT-итема',
                elector: 'электора валидаторов',
            },
        },
    },

    tx: {
        meta: {
            title: 'Эксплорер TON :: Транзакция {hash}',
        },
        title: 'Информация о транзакции',
        account: 'Аккаунт',
        status: 'Статус',
        status_success: 'ОК',
        status_failed: 'Ошибка',
        exit_codes: 'Коды выхода',
        compute_phase: 'фаза compute',
        action_phase: 'фаза action',
        phase_aborted: 'отменено',
        error_icon_tooltip: 'Код ошибки: {0}',
        exit_code_success: 'Успешно',
        exit_code_error: 'Ошибка, код выхода {0}',
        timestamp: 'Время',
        workchain: 'Воркчейн',
        seqno: 'Номер',
        shard: 'Шард',
        fee: 'Комиссия',
        message: 'Комментарий',
        hash: 'Хэш',
        lt: 'LT',
        msgs: 'Сообщения',
        msgs_empty: 'Нет сообщений',
        msgs_count: '{0} входящих, {1} исходящих',
        hash_copy_success: 'Хэш скопирован',
        lt_copy_success: 'LT скопирован',
    },

    block: {
        nav: {
            prev: 'Предыдущий блок',
            next: 'Следующий блок',
            latest: 'Последний блок',
        },
        masterchain_block_title: 'Блок мастерчейна',
        shardchain_block_title: 'Блок шардчейна',
        shardchain_blocks_title: 'Блоки шардчейна',
        transactions_title: 'Транзакции',
        show_details: 'Показать {0} унылых деталей',
    },

    blocks: {
        last_masterchain_blocks_title: 'Последние блоки мастерчейна',
    },

    nft: {
        collection: {
            meta: {
                title: 'NFT-коллекция {name} :: TON Explorer',
                description: 'Просмотр {count} NFT-итемов из коллекции {name} в эксплорере TON.',
            },
            item_count: 'Пусто | {n} штука | {n} штуки | {n} штук',
            empty_collection_header: 'Пустая коллекция',
            empty_collection_text: 'Кажется, в этой коллекции пока нет NFT. Попробуйте обновить страницу или посмотреть {0}.',
            empty_collection_text_account_link: 'информацию об адресе',
            nonseq_collection_header: 'Особая коллекция',
            nonseq_collection_text: 'Мы старались изо всех сил, но так и не смогли отобразить эту коллекцию. Попробуйте зайти на эту страницу позже или посмотреть {0}.',
            nonseq_collection_text_account_link: 'информацию об адресе',
        },
        item: {
            meta: {
                title: 'NFT-итем {name} (номер {index}) :: Эксплорер TON',
                title_standalone: 'NFT-итем {name} :: Эксплорер TON',
            },
            collection: 'Коллекция',
            no_collection: 'Без коллекции',
            owner: 'Владелец',
            number: 'Номер',
            metadata_source: 'Хранится в',
            metadata_source_onchain: 'Блокчейне',
            metadata_source_offchain: 'Интернете',
            metadata_view_source: 'открыть',
            attributes: 'Атрибуты',
            no_attributes: 'Отсутствуют',
            recent_tx: 'Недавние транзакции',
            preview: {
                index: 'Номер {index} из {total}',
                error_text: 'Ошибка загрузки NFT',
                error_button: 'Повторить',
            },
        },
        error: {
            redirect_invalid: 'Этот адрес не является NFT',
        },
    },

    jetton: {
        admin_address: 'Владелец',
        admin_address_empty: 'Отсутствует',
        admin_address_empty_explanation: '(владелец отозвал свои права)',
        supply: 'Объём',
        mutable: 'Изменяемый',
        tokens: 'Жетонов',
        metadata_source: 'Хранится в',
        search_wallet: 'Поиск',
        search_wallet_placeholder: 'Вставьте свой адрес',
        recent_tx: 'Недавние транзакции',
        top_holders: 'Топ холдеров',
        wallet: {
            title: 'Баланс жетонов',
            owner: 'Владелец',
            jetton: 'Жетон',
        },
        error: {
            redirect_invalid: 'Этот адрес не является жетоном',
        },
    },

    nominator_pool: {
        title: 'Номинатор-пул',
        title_help_link: 'https://tonblockchain.ru/p/063aba1f-a01d-4661-9c61-390b3c1dd8c2',
        button_loading: 'Загрузка...',
        button_disabled: 'Нет свободных слотов',
        button_add_stake: 'Вложить TON',
        help_text_disabled: 'невозможно добавить стейк: нет свободных слотов',
        help_text_add_stake: 'отправьте <b>{0}</b> или более <b>TON</b> с текстом &laquo;<b>d</b>&raquo; в комментарии',
        validator: 'Валидатор',
        nominator_count: 'Номинаторы',
        nominator_count_value: '{count} из {total}',
        stake: 'Стейк',
        stake_validator: 'Валидатор',
        stake_nominators: 'Номинаторы',
        stake_total: 'Всего',
        reward_share: 'Доля наград',
        reward_share_validator: 'Валидатор',
        reward_share_nominators: 'Номинаторы',
        min_stake: 'Мин. стейк',
        min_stake_validator: 'Валидатор',
        min_stake_nominator: 'Номинатор',
        tab_transactions: 'Транзакции',
        tab_nominators: 'Номинаторы',
        nominator_table_empty: 'Нет номинаторов',
        nominator_table_stake: 'Стейк',
        nominator_table_pie: 'Доля',
        nominator_table_pending: 'В ожидании',
        nominator_table_withdrawal: 'В отзыве',
        tab_votings: 'Голосования',
        voting_table_empty: 'В данный момент нет голосований',
        voting_table_proposal_hash: 'Хэш предложения',
        voting_table_created_at: 'Создано',
        votes: {
            cast_vote: 'Проголосовать',
            vote_list: 'Голоса',
            disclaimer: 'Учитываются голоса только валидатора и номинаторов.',
            vote_list_empty: 'Ещё никто не проголосовал',
            votes_table_supported: 'Голос',
            votes_table_date: 'Дата',
        },
        votes_bar: {
            title: 'Результаты голосования',
            in_favor: 'За',
            against: 'Против',
            abstained: 'Воздержались',
        },
        error: {
            redirect_invalid: 'Этот адрес не является номинатор-пулом',
        },
    },

    subscription: {
        contract_description_html: 'Это&nbsp;&ndash; контракт <strong>подписки</strong>. Раз в определённое время он отправляет ТОНы на адрес <strong>бенефициара</strong>, в обмен на доступ к какому-либо сервису (например, каналу Telegram).',
        subscriber: 'Подписчик',
        beneficiary: 'Бенефициар',
        price: 'Цена',
        first_payment_date: 'Создан',
        last_payment_date: 'Посл. платёж',
        subscription_id: 'ID подписки',
        period: {
            monthly: 'в месяц',
        },
    },

    nft_sale: {
        contract_description_html: 'Это&nbsp;&ndash; контракт <strong>продажи NFT</strong>. Он передаёт NFT новому владельцу как только тот отправит контракту необходимое количество ТОНов.',
        nft_item: 'NFT-итем',
        price: 'Цена',
        is_active: 'Активный?',
        closed_help_text: '(больше не продаётся)',
        purchase: 'Купить',
        marketplace: 'Маркетплейс',
        seller: 'Продавец',
        fees: 'Комиссия',
        marketplace_fee: 'Маркетплейса',
        royalty: 'Создателя NFT',
    },

    common: {
        address: 'Адрес',
        loading: 'Загрузка...',
        load_more: 'Загрузить ещё',
        no_description: 'Нет описания',
        unknown: 'Неизвестно',
        yes: 'Да',
        no: 'Нет',
        raw_metadata: 'Необработанные метаданные',
        nft_item: 'NFT-итем',
    },

    error: {
        invalid_address: 'Кажется, это некорректный адрес. Откуда вы его взяли?',
        invalid_tx: 'Кажется, вы перешли по некорректной ссылке на транзакцию. Откуда вы её взяли?',
    },
};
