import { render, staticRenderFns } from "./UiTouchPanel.vue?vue&type=template&id=0e2e925e&"
import script from "./UiTouchPanel.vue?vue&type=script&lang=js&"
export * from "./UiTouchPanel.vue?vue&type=script&lang=js&"
import style0 from "./UiTouchPanel.vue?vue&type=style&index=0&id=0e2e925e&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports